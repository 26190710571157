<template>
    <div class="component-container">
        <div class="info-container">
            <label class="info">{{ info }}</label>
        </div>

        <DragAndDropUpload
            @upload="handleUpload"
            :uploadtext="uploadText"
            v-bind="$attrs"
            :error="limiterror"/>

        <div style="width: 100%; overflow-y: auto;">
            <div class="file-container">
                <div class="file" v-for="(file, idx) in files" :key="idx">
                    <v-icon class="file-icon" :name="getIconToFileType(file.type)" scale="2.5"/>
                    <label>{{ file.name}}</label>
                    <v-icon class="remove" name="times" @click="removeFile(idx)"></v-icon>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import 'vue-awesome/icons/file'
import 'vue-awesome/icons/file-image'
import 'vue-awesome/icons/file-pdf'
import 'vue-awesome/icons/file-contract'
import 'vue-awesome/icons/file-code'
import 'vue-awesome/icons/file-archive'

import DragAndDropUpload from './elements/DragAndDropUpload.vue'

const EMIT_LIMIT_REACHED = 'limitReached'
const EMIT_SIZE_LIMIT_REACHED = 'sizeLimitReached'

export default {
    name: 'FileUpload',
    emits: [EMIT_LIMIT_REACHED, EMIT_SIZE_LIMIT_REACHED, ...DragAndDropUpload.emits],
    props: {
        info: String,
        value: {
            type: Array,
            required: true
        },
        limit: {
            type: Number,
            default: 0
        },
        sizeLimitMb: {
            type: Number,
            default: 0
        },
        uploadText: {
            type: String,
            default: 'Ziehen Sie Ihre Dateien in dieses Feld oder klicken Sie hier um diese hinzuzufügen.'
        }
    },
    data() {
        return {
            limiterror: undefined
        }
    },
    computed: {
        files: {
            get() {
                return this.value
            },
            set(value) {
                this.$emit('input', value)
            }
        }
    },
    methods: {
        removeFile(idx) {
            this.files.splice(idx, 1)
        },
        handleUpload(filelist) {
            this.limiterror = undefined

            const fileArray = []

            // filelist may come as an object with files as properties
            if (Array.isArray(filelist)) {
                // if its an array we just transfer the content
                fileArray.push(...filelist)
            } else {
                // else we convert the object to an array for easier handling
                Object.keys(filelist).forEach(idx => {
                    fileArray.push(filelist[idx])
                })
            }

            // add files to list if there is enough space and if all files are small enough
            const fileLimitReached = (this.limit !== 0) && (this.files.length + fileArray.length > this.limit)
            const sizeLimitReached = (this.sizeLimitMb !== 0) && fileArray.find(file => file.size > this.sizeLimitMb * 1048576) // mega byte to byte exact

            if (fileLimitReached) {
                this.limiterror = `Es können maximal ${this.limit} Datei(en) hochgeladen werden!`
                return this.$emit(EMIT_LIMIT_REACHED)
            }

            if (sizeLimitReached) {
                this.limiterror = `Dateien dürfen maximal ${this.sizeLimitMb} Mb groß sein!`
                return this.$emit(EMIT_SIZE_LIMIT_REACHED)
            }

            // else add to current list of uploaded files
            this.files.push(...fileArray)
        },
        getIconToFileType(mime) {
            let iconname = 'file'

            if (mime.startsWith('image/')) mime = 'image'

            switch(mime) {
                case 'image':
                    iconname = 'image'
                    break
                case 'text/csv':
                case 'application/msword':
                case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
                case 'text/plain':
                    iconname = 'file-contract'
                    break
                case 'text/html':
                case 'application/xhtml+xml':
                case 'application/json':
                case 'application/xml':
                case 'text/xml':
                    iconname = 'file-code'
                    break
                case 'application/pdf':
                    iconname = 'file-pdf'
                    break
                case 'application/vnd.rar':
                case 'application/x-tar':
                case 'application/zip':
                case 'application/x-7z-compressed':
                    iconname = 'file-zipper'
                    break
                default:
                    break
            }

            return iconname
        }
    },
    components: {
        DragAndDropUpload
    },
}
</script>

<style>
.info-container {
    text-align: left;
}

.info-container > .info {
    white-space: pre-wrap;
}

.file-container {
    height: 80px;
    display: flex;
    overflow-x: auto;
    overflow-y: hidden;
    margin-top: 10px;
}

.file-container > .file {
    align-items: center;
    display: flex;
    flex-direction: column;
    width: 100px;
    margin-right: 10px;
}

.file > .file-icon {
    fill: #555;
}

.file > .remove {
    fill: red;
    margin-top: 5px;
}

.file > .remove:hover {
    cursor: pointer;
}

.file > label {
    max-width: 100%;
    display: block;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    margin-top: 5px;
}
</style>