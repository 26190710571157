<template>
    <div :title="getInfoText().replace(/<[^>]*>?/gm, '')" class="ticket" :class="{'static':!selectable}" @click="$emit('click')">
        <div class="name" :class="[{'medium-text':ticket.name.length > 30}, {'long-text':ticket.name.length > 60}]">{{ ticket.name }}</div>

        <slot>
            <div class="info" v-if="getInfoText()">
                <span :class="{ 'ios-fix' : isApple }" v-if="showinfotext" v-html="getInfoText()"></span>
                <v-icon v-else name="info" scale="1.5" />
            </div>
        </slot>

        <svg class="separator" width="100%" height="3">
            <line x1="0" y1="2" x2="100%" y2="2" style="stroke-width: 4; stroke: #e2e2e6;" stroke-dasharray="10,8" />
        </svg>

        <div class="select">
            <v-icon v-if="selectable" class="select-icon" name="chevron-right" />
        </div>
    </div>
</template>

<script>
export default {
    name: 'TicketDepiction',
    props: {
        ticket: {
            type: Object,
            required: true
        },
        selectable: {
            type: Boolean,
            default: true
        },
        showinfotext: {
            type: Boolean,
            default: true
        }
    },
    data() {
        return {
            isApple: false
        }
    },
    mounted() {
        // browser try to emulate safari when running on max, so we
        // to check that the userAgent contains Safari and does NOT contain any other browser
        const safariUa  = navigator.userAgent.indexOf('Safari')  !== -1
        const chromeUa  = navigator.userAgent.indexOf('Chrome')  !== -1
        const firefoxUa = navigator.userAgent.indexOf('FireFox') !== -1
        const ieUa      = navigator.userAgent.indexOf('Trident') !== -1
        const edgeUa    = navigator.userAgent.indexOf('Edg')     !== -1
        const operaUa   = navigator.userAgent.indexOf('OPR')     !== -1

        this.isApple = safariUa && !chromeUa && !firefoxUa && !ieUa && !edgeUa && !operaUa
    },
    methods: {
        getInfoText() {
            return this.ticket.original.Infotexte.TOWSInfotext.find(text => text.Variante === 7).Text
        }
    }
}
</script>

<style>
.medium-text {
    font-size: 1em !important;
}

.long-text {
    font-size: 0.8em !important;
}

.ticket {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-height: 150px;
    background: #fff;
    border-radius: 8px;
    min-width: 220px;
    overflow: hidden;
    transition: transform 200ms ease-in-out;
    width: 100%;
    margin-bottom: 10px;
    box-shadow: 0px 2px 5px rgb(100 100 150 / 30%);
}

/* .ticket:before,
.ticket:after {
    position: absolute;
    content: " ";
    border-radius: 50%;
    width: 40px;
    height: 40px;
    background: #eeeef6;
    /* half the product height - half the dot height
    top: calc(50%  - 20px );
}

.ticket:before {
    left: -20px;
}

.ticket:after {
    right: -20px;
} */

.ticket:hover:not(.static) {
    transform: scale(1.03);
    cursor: pointer;
}

.ticket > .name {
    font-size: 1.3em;
    font-weight: bold;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    background-color: #064A6C;
}

.info {
    position: relative;
    padding: 0 30px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 5;
    -webkit-box-orient: vertical;
    margin: 10px 0;
    z-index: 10;
}

.info > span {
    text-align: left;
}

.info > span.ios-fix {
    display: block;
    overflow: hidden;
}

.info > svg {
    fill: #333;
    background-color: #cde;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    padding: 13px;
}

.separator {
    position: absolute;
    width: 100%;
    bottom: 38px;
}

.select {
    width: 100%;
    padding: 5px 20px;
    background-color: #efefef;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    height: 40px;
}
</style>