<template>
    <div class="input-wrapper" :class="[{ 'error':this.error }, {'display':this.display}]">
        <div class="spinner" v-if="isloading"></div>
        
        <input :id="computedid" autocomplete="off" :min="min" @click="clicked" :style="backgroundcolor ? 'background-color: ' + backgroundcolor + ' !important;' : ''" :type="type" :value="value" @input="handleInput($event.target.value)" :placeholder="placeholder" :disabled="disabled" :readonly="display"/>

        <label :for="inputid" class="input-label" v-if="label" :style="backgroundcolor ? 'background-color: ' + backgroundcolor + ' !important;' : ''">{{ this.label }} {{ required ? '*' : '' }}</label>

        <div class="error-container">
            <label class="error-label" :class="{ 'show' : error }">{{ this.errortext }}</label>
        </div>
    </div>
</template>

<script>
export default {
    name: 'TextInput',
    props: {
        value: {
            type: [Number, String]
        },
        type: {
            type: String,
            default: 'text'
        },
        placeholder: {
            type: String,
            default: ' ' // is used to trigger the floating label so it can not be empty
        },
        display: Boolean,
        isloading: Boolean,
        disabled: Boolean,
        label: String,
        required: Boolean,
        error: Boolean,
        errortext: String,
        backgroundcolor: String,
        min: Number,
        inputid: String,
        idstub: String
    },
    methods: {
        handleInput: function(value) {
            this.$emit('input', value)
        },
        clicked: function(event) {
            this.$emit('click', event)
        }
    },
    computed: {
        computedid: function() {
            return this.inputid || `input-${this._rng(5)}${this.idstub ? '-' + this.idstub : ''}`
        }
    }
}
</script>

<style scoped>
.input-wrapper {
    position: relative;
}

.input-wrapper {
    padding-top: 15px;
}

.input-wrapper > .input-label {
    color: #064A6C;
    position: absolute;
    font-size: 1em;
    left: 10px;
    background: #fff;
    top: 6px;
    padding: 0px 5px;
    transition: .1s all;
    height: 10px;
    line-height: 10px;
}

.input-wrapper.display > input {
    border-color:#ccc;
}

/* https://zellwk.com/blog/check-empty-input-css/ */
input:not(:placeholder-shown):not(:read-only) + .input-label,
input:focus:not(:read-only) + .input-label {
    top: 0px;
    left: 0px;
}

.display + .input-label {
    font-weight: bold;
    font-size: 1.1em;
}

.spinner + input {
    padding-right: 30px;
}

input {
    width: 100%;
    height: 30px;
    box-sizing: border-box;
    font-size: 1.2em;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    background-color: #fff;
    border-radius: var(--input-border-radius);
    padding-left: 10px;
    border: 1px solid #064A6C;
}

input::placeholder {
    font-size: 1em;
}

input:disabled {
    border-color: #ddd;
    cursor: not-allowed;
}

.textinput-container > label {
    color: #fff;
}

.input-label {
    font-size: 1.1em;
    color: #2c2e2f;
}

.error-container {
    min-height: 15px;
    margin-top: 3px;
    padding-left: 10px;
    color: red;
    text-align: left;
}

.error-container > .error-label {
    visibility: hidden;
}

.error-container > .error-label.show {
    visibility: visible;
}

.input-wrapper.error >>> * {
    color: red;
}

.input-wrapper.error > input {
    border: 1px solid red;
}

.input-wrapper > .spinner {
    position: absolute;
    height: 20px;
    width: 20px;
    border: 2px solid #064A6C;
    border-bottom: 2px solid #064A6C66;
    border-radius: 50%;
    top: 20px;
    right: 5px;

    animation-name: spin;
    animation-duration: 1500ms;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
}
</style>