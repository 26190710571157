<template>
    <div v-if="isloading" class="input-line-loading-placeholder shimmer big"></div>
    <div v-else class="input-wrapper" :class="[{'error':this.error },{'display':this.display}]">
        <DatePicker :input-attr="{ id: idstub }" value-type="format" :disabled-date="disabledfor" :placeholder="placeholder" :clearable="false" :format="format" :type="displaytype" :value="value" @input="handleInput" :disabled="disabled || display" >
            <template v-slot:icon-calendar>
                <label class="input-label" v-if="label" :style="backgroundcolor ? 'background-color: ' + backgroundcolor + ' !important;' : ''">{{ label }} {{ required ? '*' : '' }}</label>
            </template>
        </DatePicker>
        
        <div class="error-container">
            <label class="error-label" :class="{ 'show' : error }">{{ this.errortext }}</label>
        </div>
    </div>
</template>

<script>
import 'vue2-datepicker/index.css'

import DatePicker from 'vue2-datepicker'

//rje abgestimmt mit /mh: erstmal immer auf deutsch
import 'vue2-datepicker/locale/de';

export default {
    name: 'DateInput',
    props: {
        display: Boolean,
        required: Boolean,
        value: {
            type: [Date, String]
        },
        isloading: Boolean,
        time: Boolean,
        disabled: Boolean,
        displaytype: {
            type: String,
            default: 'date'
        },
        placeholder: {
            type: String,
            default: ' ' // is used to trigger the floating label so it can not be empty
        },
        label: String,
        error: Boolean,
        errortext: String,
        backgroundcolor: String,
        disabledfor: Function,
        formatDate: Function,
        idstub: String
    },
    computed: {
        type: function() {
            return this.time ? 'datetime-local' : 'date'
        },
        format: function() {
            if (this.displaytype === 'date') {
                return 'DD.MM.YYYY'
            } else if (this.displaytype === 'datetime') {
                return 'DD.MM.YYYY  -  HH:mm'
            } else {
                return 'X' // -> unix timestamp, so it's obvious that there is no format
            }
        },
        displaydate() {
            return this.value ? this.formatDate(this.value) : ''
        }
    },
    methods: {
        handleInput: function(value) {
            this.$emit('input', value)
        },
        clicked: function(event) {
            this.$emit('click', event)
        }
    },
    components: {
        DatePicker
    }
}
</script>

<style scoped>
.input-wrapper,
.input-wrapper >>> .mx-input-wrapper {
    position: relative;
}

.input-wrapper >>> .mx-input-wrapper {
    padding-top: 15px;
}

.input-wrapper >>> i {
    position: absolute;
    font-size: 1em;
    left: 10px;
    background: #fff;
    top: 12px;
    padding: 0px 5px;
    transition: .1s all;
    cursor: text;
    font-style: unset;
    width: fit-content;
    line-height: 10px;
}

/* https://zellwk.com/blog/check-empty-input-css/ */
.input-wrapper:not(.display) >>> input:not(:placeholder-shown) + .mx-icon-calendar,
.input-wrapper:not(.display) >>> input:focus + .mx-icon-calendar {
    top: 5px;
    left: 0px;
}

.input-wrapper >>> input {
    width: 100%;
    height: 30px;
    box-sizing: border-box;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    background-color: #fff;
    border-radius: var(--input-border-radius);
    padding-left: 10px;
    border: 1px solid #064A6C;
}

.input-wrapper.display >>> input {
    border-color: #ccc;
}

.input-wrapper >>> input:disabled {
    border-color: #ddd;
    cursor: not-allowed;
}

.input-wrapper >>> input::placeholder {
    font-size: 1em;
}

.input-wrapper >>> input {
    padding-top: 5px;
    /* height: 35px; */
}

.textinput-container > label {
    color: #fff;
}

.input-label {
    color: #2c2e2f;
}

.error-container {
    min-height: 15px;
    margin-top: 3px;
    padding-left: 10px;
    color: red;
    text-align: left;
}

.error-container > .error-label {
    visibility: hidden;
}

.error-container > .error-label.show {
    visibility: visible;
}

.input-wrapper.error >>> * {
    color: red;
}

.input-wrapper.error >>> input {
    border: 1px solid red;
}
</style>

<style>
/* default overrides for the datepicker classes */

*[class*='mx-'] {
    color: black;
    font-family: inherit;
}

.mx-icon-calendar {
    color: #fff;
}

.mx-input {
    box-shadow: none;
    padding-left: 0px;
    font-size: 1.2em;
}

.mx-input:disabled {
    color: rgb(84, 84, 84);
    background: rgba(239, 239, 239, 0.3);
}

.mx-icon-calendar:hover {
    cursor: pointer;
}

.mx-datepicker {
    width: 100%;
}
</style>