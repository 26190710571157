import Vue from 'vue'
import DonePage from '@/views/DonePage.vue'
import VueRouter from 'vue-router'
import LandingPage from '@/views/LandingPage.vue'
import PersonalDataPage from '@/views/PersonalDataPage.vue'
import CompleteOrderPage from '@/views/CompleteOrderPage.vue'
import ProductDetailsPage from '@/views/ProductDetailsPage.vue'
import ProductSelectionPage from '@/views/ProductSelectionPage.vue'

Vue.use(VueRouter)

// replace the original "push" method to surpress "Redirected via navigation guard" erros
// since we're always appending the query params, we always navigate via a navigation guard
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location, onResolve, onReject) {
  if (onResolve || onReject) return originalPush.call(this, location, onResolve, onReject)
  return originalPush.call(this, location).catch(err => err)
}

const DEFAULT_ROUTE_NAME = 'landing'

const routes = [
    {
        // this is only used to load data and redirect
        // to the correct page
        path: '/',
        name: DEFAULT_ROUTE_NAME,
        component: LandingPage,
    },
    {
        path: '/products',
        name: 'product-selection',
        component: ProductSelectionPage,
        meta: {
            menuentry: true,
            display: 'nav.product-selection',
            weight: 0
        },
        beforeEnter: (to, from, next) => {
            const appdata = router.app.$options.data()

            // if there is a selected product, and we're not coming from
            // the product details, we do NOT do back all the way
            if (from.name !== 'product-details' && appdata.product) {
                next({name: 'product-details', query: to.query })
            } else {
                next()
            }
        }
    },
    {
        path: '/products/details',
        name: 'product-details',
        component: ProductDetailsPage,
        meta: {
            menuentry: false,
            subnav: true,
            display: 'nav.product-details',
            highlight: 'product-selection',
            weight: 1
        }
    },
    {
        path: '/personal',
        name: 'personal-data',
        component: PersonalDataPage,
        meta: {
            menuentry: true,
            display: 'nav.personal-data',
            weight: 2
        }
    },
    {
        path: '/complete',
        name: 'complete-order',
        component: CompleteOrderPage,
        meta: {
            menuentry: true,
            display: 'nav.complete-order',
            weight: 3
        }
    },
    {
        path: '/done',
        name: 'done',
        component: DonePage,
        meta: {
            withoutmenu: true,
            weight: 4
        }
    },
    // ----- DEFAULT ROUTE MUST BE LAST! -----
    {
        path: '*',
        name: 'else',
        component: undefined,
        // always redirect to landing page
        beforeEnter: (to, from, next) => {
            next({name: DEFAULT_ROUTE_NAME, query: to.query })
        }
    }
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.NODE_ENV === 'production' ? '/anlageassistent' : '',
    routes
})

router.beforeEach((to, from, next) => {
    // preserve query
    // we merge both querys in case the to.query is not emtpy
    // parameters present in both querys will be overwritten by the to.query values!
    const fromhasquery = !!Object.keys(from.query).length
    const tohasquery = !!Object.keys(to.query).length

    if ((fromhasquery && !tohasquery)) {
        Object.assign(to.query, { ...from.query, ...to.query}) // route.query is readonly, so we can't do this: to.query = { ...from.query, ...to.query}
    }

    const appdata = router.app.$options.data()

    const gotolanding = !appdata.landed && to.name !== DEFAULT_ROUTE_NAME
    const gotoproducts = !appdata.product && to.name !== 'product-selection'

    if ((fromhasquery && !tohasquery)) {
        next(to)
    } else if (gotolanding) {
        to = { name: DEFAULT_ROUTE_NAME, query: to.query }
        next(to)
    } else if (appdata.landed && gotoproducts) {
        to = { name: 'product-selection', query: to.query }
        next(to)
    } else {
        next()
    }
})

export default router