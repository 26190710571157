import { render, staticRenderFns } from "./Check.vue?vue&type=template&id=db603c2a&scoped=true"
import script from "./Check.vue?vue&type=script&lang=js"
export * from "./Check.vue?vue&type=script&lang=js"
import style0 from "./Check.vue?vue&type=style&index=0&id=db603c2a&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "db603c2a",
  null
  
)

export default component.exports