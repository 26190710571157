<template>
    <div v-if="isloading" class="input-line-loading-placeholder shimmer big"></div>
    <div v-else class="input-wrapper" :class="[{'error':this.error },{'display':this.display}]">
        <div class="input-container" :id="uniqueid" v-if="!display">
            <div class="option-container" v-for="option in options" :key="option.displayid" :class="{ 'selected':option.displayid === value}">
                <input type="radio" :id="uniqueid + '-option-' + option.displayid" :value="option.displayid" v-model="selected"/>
                <label :for="uniqueid + '-option-' + option.displayid">{{ option.displayname }}</label>
            </div>
        </div>

        <label class="display" v-else>{{ displayvalue }}</label>

        <label :for="inputid" class="input-label" v-if="label" :style="backgroundcolor ? 'background-color: ' + backgroundcolor + ' !important;' : ''">{{ this.label }} {{ required ? '*' : '' }}</label>

        <div class="error-container">
            <label class="error-label" :class="{ 'show' : error }">{{ this.errortext }}</label>
        </div>
    </div>
</template>

<script>
export default {
    name: 'SelectOneInput',
    props: {
        value: {
            type: Number,
            required: true
        },
        options: {
            type: Array,
            required: true
        },
        display: Boolean,
        isloading: Boolean,
        label: String,
        required: Boolean,
        error: Boolean,
        errortext: String,
        backgroundcolor: String,
        inputid: String,
        idstub: String
    },
    computed: {
        uniqueid: function() {
            return this.inputid || `input-${this._rng(5)}${this.idstub ? '-' + this.idstub : ''}`
        },
        selected: {
            get() {
                return this.value
            },
            set(value) {
                this.$emit('input', this.options.find(option => option.displayid === value))
            }
        },
        displayvalue() {
            return this.options.find(option => option.displayid === this.selected).displayname
        }
    },
    methods: {
        handleInput: function(value) {
            this.$emit('input', value)
        },
        clicked: function(event) {
            this.$emit('click', event)
        }
    },
}
</script>

<style scoped>
.input-wrapper {
    position: relative;
}

.input-wrapper {
    padding-top: 15px;
}

.input-wrapper > .input-container {
    display: block;
}

.input-container > .option-container {
    border: 1px solid #ccc;
    border-radius: var(--input-border-radius);
    min-width: 135px;
    display: flex;
    align-items: center;
    height: 30px;
    margin-top: 5px;
    margin-right: 5px;
    flex-grow: 1;
    user-select: none;
}

.input-container > .option-container:hover,
.input-container > .option-container:hover > * {
    cursor: pointer;
}

.input-container > .option-container.selected {
    border: 1px solid #064A6C;
    background-color: #064A6C22;
}

.option-container > input {
    font-size: 1.2em;
    margin-top: 0px;
    width: 20px;
    height: 30px;
}

.option-container > label {
    flex: 1;
    height: 30px;
    line-height: 30px;
    font-size: 1.2em;
}

.input-wrapper > .input-label {
    position: absolute;
    background: #fff;
    transition: .1s all;
    height: 10px;
    line-height: 10px;
    top: 0px;
    left: 0px;
    font-weight: bold;
    font-size: 1.1em;
}

.input-wrapper > .display {
    margin-top: 5px;
    display: block;
    white-space: pre-wrap;
    text-align: left;
    margin-left: 20px;
}

.textinput-container > label {
    color: #fff;
}

.input-label {
    font-size: 1.1em;
    color: #2c2e2f;
}

.error-container {
    min-height: 15px;
    margin-top: 3px;
    padding-left: 10px;
    color: red;
    text-align: left;
}

.error-container > .error-label {
    visibility: hidden;
}

.error-container > .error-label.show {
    visibility: visible;
}

.input-wrapper.error >>> * {
    color: red;
}

.input-wrapper.error > input {
    border: 1px solid red;
}
@media(min-width: 576px) {
    .input-wrapper > .input-container {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
    }

    .input-container > .option-container {
        max-width: 250px;
    }
}
</style>