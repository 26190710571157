<template>
    <div class="information-container round-container">
        <div class="product-container">
            <div class="product-header">
                <label id="aW5mb3Byb2R1a3Q=">{{ this.product.name }}</label>
            </div>

            <div v-if="trip" class="trip-depiction">
                <svg height="100" width="25">
                    <line x1="5" y1="9" x2="5" y2="91" style="stroke-width: 2; stroke: black;" stroke-dasharray="5,2.7" />
                    <circle cx="5" cy="9" r="5" fill="black"/>
                    <circle cx="5" cy="91" r="5" fill="black"/>
                </svg>
                <div class="trip-information">
                    <label id="aW5mb2Zyb20=">{{ this.trip.VonTarifstellenOrt.TarifstellenOrtText }}</label>
                    <label id="aW5mb3ZpYQ==" class="via" v-if="true">via {{ this.relation.Relationsanzeigetext }}</label>
                    <label id="aW5mb3Rv">{{ this.trip.NachTarifstellenOrt.TarifstellenOrtText }}</label>
                </div>
            </div>

            <div class="product-information" v-if="relation">
                <div>
                    <label>{{ $t('general.valid-from') }}</label>
                    <label id="aW5mb3ZhbGlkaXR5">{{ product.validity.displayname }}</label>
                </div>
                <div>
                    <label>{{ $t('general.price-level') }}</label>
                    <label id="aW5mb3ByZWlzc3R1ZmU=" >{{ `${relation.Gesamtpreisstufe}${relation.GesamtpreisstufenBezeichnung? ' - ' + relation.GesamtpreisstufenBezeichnung : ''}` }}</label>
                </div>
                <div>
                    <label>{{ $t('general.variant') }}</label>
                    <label id="aW5mb3Byb2R1a3R2YXJpYW50ZQ==">{{ product.variant }}</label>
                </div>
            </div>
        </div>
    
        <div class="price-container" v-if="this.fullprice">
            <div>
                {{ $t('general.price') }}
            </div>
            <div id="aW5mb3ByZWlz">
                {{ _currencyValueToFloat(this.fullprice) + '€' }}
            </div>
        </div>

        <div id="aW5mb2VpZ2VuYW50ZWls" class="price-comment" v-if="this.fullprice !== this.price">
            {{ `Eigenanteil ${_currencyValueToFloat(this.price)}€` }}
        </div>

        <div v-if="pricehint">
            <label class="price-hint" v-html="pricehint" />
        </div>

        <div class="price-hint">
            <label id="aW5mb3ByZWlzdGV4dA==">{{ alternativetext }}</label>
        </div>
    </div>
</template>

<script>
export default {
    name: 'TicketInformationContainer',
    props: {
        product: Object,
        relation: Object
    },
    computed: {
        price() {
            if (this.relation) {
                return this.relation.EigenanteilInCent
            }

            return null
        },
        fullprice() {
            if (this.relation) {
                return this.relation.StreckenPreisInCent
            }
            return null
        },
        trip() {
            if (this.relation 
                && this.relation.RelationVonNachTarifstellenOrte 
                && this.relation.RelationVonNachTarifstellenOrte.TOWSVonTarifstellenOrtNachTarifstellenOrt) {
                    return this.relation.RelationVonNachTarifstellenOrte.TOWSVonTarifstellenOrtNachTarifstellenOrt[0]
            }
            return null
        },
        alternativetext() {
            // 18 === PRODUKT_INFO_ALTERMATIVTEXT
            var result = undefined
            if (this.product.original.Bescheinigungen
                    && this.product.original.Bescheinigungen.TOWSBescheinigung) {
                const text = this.product.original.Bescheinigungen.TOWSBescheinigung.find(entry => entry.Variante === 18)
                if (text) result = text.Text
            }
            return result
        },
        pricehint() {
            // 8 === TARIF
            var result = undefined
            if (this.product.original.Infotexte
                    && this.product.original.Infotexte.TOWSInfotext) {
                const text = this.product.original.Infotexte.TOWSInfotext.find(entry => entry.Variante === 8)
                if (text) result = text.Text
            }
            return result
        }
    },
}
</script>

<style scoped>
.information-container {
    padding: 15px;
    background-color: #fff;
    min-height: 100px;
    display: flex;
    flex-direction: column;
    box-shadow: 0px 2px 5px rgb(100 100 150 / 30%);
}

.information-container > .product-container {
    display: flex;
    flex-direction: column;
    height: 100%;
    margin-bottom: 20px;
    font-size: 1.2em;
}

.product-container > .trip-depiction {
    display: flex;
    margin-left: auto;
    margin-right: auto;
    max-width: 100%;
    overflow: hidden;
}

.trip-depiction > svg {
    flex: 1;
}

.trip-depiction > .trip-information {
    flex: 9;
    text-align: left;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-left: 10px;
    padding: 3px 0;
}

.trip-depiction > .trip-information > label {
    display: block;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.trip-information > .via {
    color: #666;
}

.product-container > div:not(:first-child) {
    margin-top: 20px;
}

.product-container > .product-information {
    margin-top: auto;
    text-align: left;
}

.product-container > .product-information > div {
    margin-top: 5px;
    display: flex;
    justify-content: space-between;
}

.product-container > .product-information > div > label:nth-of-type(2) {
    font-weight: bold;
}

.product-container > .product-header {
    text-align: center;
    font-weight: bold;
    font-size: 1.25em;
}

.information-container > .price-container {
    margin-top: auto;
    font-weight: bold;
    font-size: 1.5em;
    display: flex;
    justify-content: space-between;
}

.price-comment {
    text-align: right;
}

.price-hint {
    text-align: justify;
}
</style>