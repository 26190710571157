<template>
    <div class="footer-container">
        <div class="link-container" v-for="link in legallinks" :key="link.ID">
            <a class="link" :href="link.Link" target="_blank">{{ link.Name }}</a>
        </div>

        <LanguageChanger />
    </div>
</template>

<script>
import LanguageChanger from './systems/elements/LanguageChanger.vue'

export default {
    name: 'Footer',
    computed: {
        legallinks() {
            const availablelinks = this.$root.$data.systemconfiguration.linklist
            const idprivacy = this.$root.$data.systemconfiguration.configuration?.IDLinkDatenschutz
            const idimprint = this.$root.$data.systemconfiguration.configuration?.IDLinkImpressum
            const idcancellation = this.$root.$data.systemconfiguration.configuration?.IDLinkKuendigung

            let links = availablelinks.filter(entry => (entry.ID === idprivacy || entry.ID === idimprint || entry.ID === idcancellation))
            //order links
            if(idcancellation && links.length === 3) {
                links = [links[2], links[0], links[1]]
            }
            return links
        }
    },
    components: {
        LanguageChanger
    }
}
</script>

<style scoped>
.footer-container {
    flex-direction: row;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    height: 100%;
}

.footer-container > .link-container {
    padding: 0 10px;
}

.footer-container > .link-container:not(:last-child) {
    border-right: 1px solid #999;
}

.link-container > .link {
    text-decoration: none;
    color: #064A6C;
}
</style>