<template>
    <div class="cropper-container">
        <Cropper
            ref="cropper"
            class="cropper round-container"
            :src="croppersrc"
            :stencil-props="stencilprops"
            :minWidth="cropperprops.minWidth"
            :minHeight="cropperprops.minHeight"
            :auto-zoom="cropperprops.autozoom"
            :debounce="cropperprops.debounce"
            :image-restriction="cropperprops.imgrestriction"
            @change="handleCrop"/>

        <div class="result">
            <Preview class="preview"
                :image="cropperresult.image"
                :width="150"
                :height="225"
                :coordinates="cropperresult.coordinates"/>
        </div>

        <div class="button-container">
            <button v-if="canchangepicture" class="new round-container" @click="reset">{{ $t('general.change-picture') }}</button>
            <button class="confirm round-container" @click="confirmPicture">{{ $t('general.submit-picture') }}</button>
        </div>
    </div>
</template>

<script>
import { Cropper, Preview } from 'vue-advanced-cropper'

import 'vue-advanced-cropper/dist/style.css';

export default {
    name: 'cropper',
    props: {
        croppersrc: String,
        cropperresult: [Object, String],
        stencilprops: Object,
        cropperprops: Object,
        canchangepicture: {
            type: Boolean,
            default: true
        }
    },
    methods: {
        handleCrop(event) {
            this.$emit('handleCrop', event)
        },
        reset() {
            this.$emit('reset')
        },
        confirmPicture() {
            const { canvas } = this.$refs.cropper.getResult()
            this.$emit('confirmPicture', canvas.toDataURL('image/jpeg'))
        }
    },
    components: {
        Cropper,
        Preview
    }
}
</script>

<style scoped>
.cropper-container {
    position: relative;
    width: 100%;
    height: 100%;
}
.cropper {
    width: 250px;
    height: 375px;
    margin: 0 auto;
    overflow: hidden;
}

.cropper >>> .vue-advanced-cropper__background {
    background: #fff;
}

.result {
    width: 100%;
    margin-top: 20px;
    position: absolute;
    top: 0;
    left: 0;
    visibility: hidden;
}

.result > div.preview {
    width: 150px;
    height: 225px;
    background-color: #eee;
    border: 1px solid #999;
    border-radius: 8px;
}

.result > label {
    display: block;
    font-weight: bold;
    font-size: 1.5em;
}

.button-container {
    margin: 0 auto;
    margin-top: 16px;
}

.button-container > button {
    margin: 0;
    padding: 8px 12px;
    font-size: 1.5em;
    font-weight: bold;
    display: block;
    width: 100%;
}

.button-container > .new {
    color: #333;
    background-color: #ccc;
    margin-bottom: 10px;
}

.button-container > .confirm {
    color: #fff;
    background-color: #064A6C;
}

.button-container > .new:hover {
    background-color: #cccccccc;
}

.button-container > .confirm:hover {
    background-color: #064A6Ccc;
}

@media(min-width: 576px) {
    .cropper {
        width: 250px;
        height: 375px;
        margin: 0;
    }

    .result {
        margin-top: 0;
        margin-left: 10em;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        width: unset;
    }
}
</style>