<template>
    <div class="locale-changer">
        <div class="locale-changer">
            <div v-for="(lang, i) in langs" :key="`Lang${i}`" class="locale" :class="{ 'active' : $i18n.locale === lang }" @click="changelocale(lang)">
                <label>{{ lang }}</label>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'locale-changer',
    computed: {
        langs() {
            return this.$i18n.availablelocales
        }
    },
    methods: {
        changelocale: function(locale) {
            this.$i18n.locale = locale
        }
    }
}
</script>

<style scoped>
.locale-changer {
    float: right;
    display: flex;
    justify-content: space-evenly;
    height: fit-content;
    align-items: center;
}

.locale {
    padding: 0 10px;
    color: #aaa;
    text-transform: uppercase;
    font-weight: bold;
    font-size: 1.2em;
}

.locale:hover >>> * {
    cursor: pointer;
}

.locale.active {
    color: #0069B4;
}

.locale:not(:last-child) {
    border-right: 1px solid #aaa;
}
</style>