<template>
    <div class="page-container">
        <div class="content-container">
            <div v-if="corporationselection">
                <div class="school-selection-header">
                    <label>{{ this.schoolselection ? $t('product-selection.school-selection') : $t('product-selection.corporation-selection') }}</label>
                </div>
                <div class="school-selection">
                    <DropdownInput idstub="c2NodWxhdXN3YWhs" :defaultlabel="$t('general.please-select')" :label="this.schoolselection ? $t('product-selection.school') : $t('product-selection.corporation')" :optionlist="corporationlist" :defaultoption="true" :value="selectedcorporation.displayid" @input="handleCorporationSelection"/>
                </div>
            </div>

            <div class="highlight-container" v-if="(!highlights || highlights.length > 0) && (corporationselection ? this.corporation : true)">
                <div class="highlight-header">
                    <label>{{ $t('product-selection.highlights') }}</label>
                </div>
                <div class="highlight-products">
                    <TicketDepiction class="highlight-product" :showinfotext="false" v-for="product in highlights" :key="product.id" :ticket="product" @click="goToDetails(product)"/>
                </div>
            </div>

            <div class="product-list" v-if="corporationselection ? this.corporation : true">
                <div class="products-header">
                    <label>{{ $t('product-selection.all-products') }}</label>
                </div>
                <div class="products">
                    <TicketDepiction v-for="product in productlist" :key="product.id" :ticket="product" @click="goToDetails(product)" />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios'
import Vue from 'vue'

import 'vue-awesome/icons/chevron-right'
import 'vue-awesome/icons/info'

import TicketDepiction from '../components/systems/elements/TicketDepiction.vue'
import DropdownInput from '../components/systems/elements/DropdownInput.vue'

export default {
    name: 'ProductSelectionPage',
    data() {
        return {
            highlightproducts: this.$root.$data.systemconfiguration.anlageassistent.highlights,
            corporationlist: [],
            selectedcorporation: { displayid: -1 }
        }
    },
    mounted() {
        this.init()
    },
    computed: {
        corporationselection() {
            return this.$route.query.co ? this.$route.query.co === '1' : false || this.$route.query.cs ? this.$route.query.cs === '1' : false
        },
        schoolselection() {
            return this.$route.query.cs ? this.$route.query.cs === '1' : false
        },
        corporation() {
            return this.$route.query.KTNR
        },
        highlights() {
            return this.productlist ? this.productlist.filter(product => this.highlightproducts.includes(product.id.toString())) : []
        },
        productlist: {
            get() {
                return this.$root.$data.availableproducts
            },
            set(value) {
                this.$root.$data.availableproducts = value
            }
        }
    },
    methods: {
        init() {

            if (!this.corporationselection && (!this.productlist || this.productlist.length == 0)) {
                this.loadProducts()
            } else if (this.corporationselection) {
                this.initCorporations()
            }
        },
        handleCorporationSelection(event) {
            this.selectedcorporation = event

            // do not reload for default item
            if (event.displayid === -1) {
                return
            }

            // set the app to loading, this will be canceled
            // by the redirect
            this.$root.$data.loading = true

            // alter the query param on the current route
            // this works and is ok because we will not use this
            // $route instance again -> at this point we will redirect
            if(this.schoolselection) {
                this.$route.query.KTNR = event.original.Schulnummer
            } else {
                this.$route.query.KTNR = event.original.nr
            }

            // start the query with '?' and append every query entry
            let query = '?'
            Object.keys(this.$route.query).forEach(key => {
                query += `&${key}=${this.$route.query[key]}`
            })

            // replace the first '?&' to get a valid query
            let redirecturl = location.origin + location.pathname + query.replace('?&', '?')
            window.location = redirecturl
        },
        initCorporations() {
            this.$root.$data.loading = true

            let url = Vue.middleware()
            if(this.schoolselection) {
                url += '/clients/' + this.$root.$data.session.clientid
                url += '/schools'
            } else {
                url += '/clients/' + this.$root.$data.session.clientid
                url += '/available-corporations'
            }

            axios.get(url)
            .then(response => {
                const status = response.data.status
                const data = response.data.data

                if (status.ErrorCode !== 0) {
                    // TODO: toast?
                    console.log(status)
                    return
                }

                if(this.schoolselection) {
                    this.corporationlist = data.TOWSSchule.map(school => {
                        return {
                            displayid: school.IDKoerperschaft,
                            displayname: school.Schulnummer + ' ' + school.Schulbezeichnung,
                            original: school
                        }
                    })

                    if (this.corporation) {
                        const urlcorporation = this.corporationlist.find(entry => entry.original.Schulnummer === this.corporation)
                        if (urlcorporation) {
                            this.selectedcorporation = urlcorporation
                            this.loadProducts()
                        }
                    }
                } else {
                    this.corporationlist = data.corporationlist.map(corporation => {
                        return {
                            displayid: corporation.id,
                            displayname: corporation.nr + ' ' + corporation.name,
                            original: corporation
                        }
                    })

                    if (this.corporation) {
                        const urlcorporation = this.corporationlist.find(entry => entry.original.nr === this.corporation)
                        if (urlcorporation) {
                            this.selectedcorporation = urlcorporation
                            this.loadProducts()
                        }
                    }
                }

                this.$root.$data.loading = false
            })
            .catch(err => {
                console.log(err)
                this.$root.$data.loading = false
            })
        },
        loadProducts() {
            this.$root.$data.loading = true
            
            const session = this.$root.$data.session

            let url = Vue.middleware()
            url += `/clients/` + session.clientid
            url += `/corporations/` + session.corporationid
            url += `/available-products`

            axios.get(url)
            .then(response => {

                if (response.data.status.ErrorCode !== 0) {
                    // TODO:
                    return
                }

                const resultlist = response.data.data.productlist
                this.productlist = resultlist.map(entry => {
                    return {
                        id: entry.IDProduktZuordnung,
                        name: entry.ProduktZuordnungBezeichnung,
                        info: '',
                        original: entry
                    }
                })

                this.$root.$data.loading = false
            })
            .catch(err => console.log(err))
        },
        goToDetails(product) {
            this.$root.$data.product = product
            this.$router.push({ name: 'product-details' })
        },
        getInfoText(product) {
            return product.original.Infotexte.TOWSInfotext.find(text => text.Variante === 7).Text
        }
    },
    components: {
        TicketDepiction,
        DropdownInput
    }
}
</script>

<style scoped>
.medium-text {
    font-size: 1em !important;
}

.long-text {
    font-size: 0.8em !important;
}

.content-container {
    height: fit-content;
}

.content-container > .highlight-container {
    margin-bottom: 30px;
}

.highlight-container > .highlight-header {
    text-align: left;
    font-size: 1.5em;
    font-weight: bold;
}

.highlight-container > .highlight-products {
    display: flex;
    margin-top: 10px;
    overflow-x: auto;
    padding-bottom: 10px;
}

.highlight-products > .highlight-product {
    margin: 5px;
    width: 250px;
}

.content-container > .product-list {
    flex: 1;
}

.school-selection-header,
.product-list > .products-header {
    text-align: left;
    font-size: 1.5em;
    font-weight: bold;
    margin-bottom: 10px;
}

.product-list > .products {
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
}

.products > .product:not(:last-child) {
    margin-bottom: 10px;
}

.products > .product:hover {
    background: #00000011;
}

.products > .product:hover,
.products > .product:hover >>> * {
    cursor: pointer;
}

.select > .select-icon {
    margin-left: 10px;
}

.school-selection {
    border-radius: 8px;
    background: #fff;
    box-shadow: 0px 2px 5px rgb(100 100 150 / 30%);
    padding: 10px 50px;
    margin-bottom: 50px;
}

@media(min-width: 992px) {
    .products > div {
        width: 49%;
    }
}
</style>
