<template>
    <div class="page-container">
        <div class="content-container round-container">
            <label class="heading">{{ $t('done-page.heading') }}</label>

            <Check class="check"/>

            <label id="aGlud2Vpcw==" class="complete-hint" v-if="completehint" v-html="completehint"></label>
            <label v-else class="complete-hint">{{ $t('done-page.info') }}</label>

            <label v-if="email" class="confirmation">{{ $t('done-page.confirmation') }}</label>
            <label id="bWFpbA==" v-if="email" class="email">{{ email }}</label>


            <label id="dW1mcmFnZQ==" class="survey-hint" v-if="surveylink && surveyhint" v-html="surveyhint"></label>
            <a id="dW1mcmFnZWxpbms=" class="survey-link" v-if="surveylink" :href="surveylink">{{ surveylink }}</a>

            <button id="back-to-app" v-if="returnurl" class="back round-container" @click="backToStart">{{ $t('done-page.back-to-app') }}</button>
        </div>
    </div>
</template>

<script>
import Check from '../components/systems/elements/Check.vue'

export default {
    name: 'DonePage',
    data() {
        return {
            email:        undefined,
            completehint: undefined,
            surveyhint:   undefined,
            surveylink:   undefined,
            returnurl:    undefined,
        }
    },
    mounted() {
        // get the needed texts before resetting
        this.saveTexts()
        // reset data, so the contract can't be sent twice
        this.reset()
    },
    methods: {
        saveTexts() {
            const product = this.$root.$data.product
            // 15 === ABSCHLUSS_TARIFWECHSEL
            // 11 === ABO_AUFTRAG_ABGESCHLOSSEN
            // an existing customer gets a different hint
            const completetextid = this.$root.$data.isexistingcustomer ? 15 : 11
            
            this.surveylink   = product.OnlineSurveyLink
            this.completehint = this.getText(product, completetextid)
            this.surveyhint   = this.getText(product, 12)
        },
        getText(product, variant) {
            var result = undefined
            if (product.original.Infotexte
                    && product.original.Infotexte.TOWSInfotext) {
                const text = product.original.Infotexte.TOWSInfotext.find(entry => entry.Variante === variant)
                if (text) result = text.Text
            }
            return result
        },
        reset() {
            // trying to do a graceful reset
            this.returnurl = this.$root.$data.successurl
            this.email     = this.$root.$data.customer ? this.$root.$data.customer.email : ''
            this.$root.$data.reset()
        },
        backToStart() {
            // deliberately pushing a non existing route
            // to trigger a reset
            window.location = this.returnurl
        }
    },
    components: {
        Check
    }
}
</script>

<style scoped>
.page-container {
    height: calc(100% - 60px - 30px) !important;
}

.content-container {
    color: #333;
    display: flex;
    flex-direction: column;
    padding: 20px;
    background: #fff;
    justify-content: center;
    align-items: center;
    box-shadow: 0px 2px 5px rgb(100 100 150 / 30%);
}

.content-container > .heading {
    font-weight: bold;
    font-size: 2em;
}

.content-container > .check {
    margin: 50px 0;
}

.content-container > .complete-hint {
    text-align: center;
    font-size: 1.2em;
}

.content-container > .survey-hint {
    margin-top: 25px;
}

.content-container > .survey-link {
    margin-top: 10px;
}

.content-container > .confirmation {
    font-size: 1.2em;
    margin-top: 20px;
}

.content-container > .email {
    margin: 20px 0;
    font-weight: bold;
    font-size: 1.4em;
    color: #064A6C;
}

.content-container > .back {
    margin-top: 50px;
    padding: 10px;
    color: #fff;
    background: #064A6C;
}

.content-container > .back:hover {
    background: #064A6Ccc;
}
</style>