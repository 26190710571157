<template>
    <div class="overlay" v-if="value">
        <div class="content-container">
            <div v-if="!croponly && uploadnotcrop" class="content phase-one" v-click-outside="close">
                <div class="close-container" @click="close">
                    <v-icon name="times" />
                </div>
                <DragAndDropUpload
                    class="drop-area"
                    @upload="handleUpload"
                    :uploadtext="uploadtext"
                    v-bind="$attrs"
                    acceptedFileTypes="image"
                    :uploadlimit="1"
                    @uploadLimit="handleUploadLimitReached"
                    :error="uploaderror"/>
            </div>
            <div v-else-if="croponly || !uploadnotcrop" class="content phase-two round-container">
                <div class="close-container" @click="close">
                    <v-icon name="times" />
                </div>

                <Cropper
                    :croppersrc="croponly ? src : croppersrc"
                    :cropperresult="cropperresult"
                    :stencilprops="stencilprops"
                    :cropperprops="cropperprops"
                    :canchangepicture="false"
                    @confirmPicture="confirmPicture"
                    @handleCrop="handleCrop"
                    @reset="reset"/>
            </div>
        </div>
    </div>
</template>

<script>
import 'vue-awesome/icons/times'

import DragAndDropUpload from './elements/DragAndDropUpload.vue'
import Cropper from './elements/Cropper.vue'

const EMIT_PICTURE = 'picture'
const EMIT_INPUT = 'input'

export default {
    name: 'ImageUploadCropper',
    emits: [EMIT_INPUT, EMIT_PICTURE, ...DragAndDropUpload.emits],
    props: {
        value: Boolean,
        uploadtext: String,
        // is used when only the cropper is needed
        src: {
            type: String,
            default: undefined
        },
        croponly: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            uploaderror: undefined,
            uploadnotcrop: true,
            croppersrc: '',
            cropperresult: '',
            stencilprops: {
                aspectRatio: 2/3,
            },
            cropperprops: {
                minHeight: 250,
                minWidth: 200,
                autozoom: true,
                debounce: false,
                imgrestriction: 'stencil'
            },
        }
    },
    methods: {
        handleUploadLimitReached() {
            this.uploaderror = `Es kann maximal ein Bild hochgeladen werden!`
        },
        handleUpload(event) {
            this.uploaderror = undefined
            
            if (event && event.length > 0) {
                this.readImage(event[0])
                this.uploadnotcrop = false
            }
        },
        handleCrop(event) {
            this.cropperresult = event
        },
        readImage(file) {
            const reader = new FileReader()
            reader.addEventListener('load', (event) => {
                this.croppersrc = event.target.result
            })

            reader.readAsDataURL(file)
        },
        confirmPicture(event) {
            this.$emit(EMIT_PICTURE, event)
            this.close()
        },
        reset() {
            this.uploadnotcrop = true
            this.croppersrc = ''
            this.cropperresult = ''
        },
        close() {
            this.reset()
            this.$emit(EMIT_INPUT, false)
        }
    },
    components: {
        DragAndDropUpload,
        Cropper
    }
}
</script>

<style scoped>
.overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #00000066;
}

.content-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    border-radius: 8px;
}

.content-container > .content {
    position: relative;
    width: 100%;
}

.content-container > .phase-two {
    background: #fff;
    padding: 20px 50px;
    display: flex;
    flex-wrap: wrap;
    width: fit-content;
}

.content > .close-container {
    position: absolute;
    height: 30px;
    width: 30px;
    line-height: 38px;
    border-radius: 50%;
    background-color: #fff;
    top: -35px;
    right: 10px;
    user-select: none;
}

.content > .close-container:hover {
    background: #ddd;
    cursor: pointer;
}

.drop-area >>> .upload-wrapper {
    height: 200px;
}
</style>