<template>
    <div class="auto-complete-wrapper" @keyup="nextItem" @keyup.enter="manualEntrySelect">
        <TextInput :idstub="idstub" v-bind="$attrs" v-model="inputvalue" @input="triggerSearch" :disabled="disabled" :isloading="isloading" @click="openResults"/>
        <v-icon v-if="selected && !isloading" class="reset" name="times" @click="clearSelection" />
        <ul class="result-list" v-if="resultsopen" v-click-outside="closeResults">
            <li :class="{ 'active' : idx === currentitem }" class="result-entry" v-for="(result, idx) in resultlist" :key="result.displayid" @click="handleSelection(result)">{{ result.displayname }}</li>
        </ul>
    </div>
</template>

<script>
import _ from 'lodash'

import TextInput from './elements/TextInput.vue'

export default {
    name: 'AutoCompleteInput',
    props: {
        value: {
            type: String,
            required: true
        },
        completemethod: {
            type: Function, // must return a promise with a list of results
            required: true
        },
        loading: Boolean,
        disabled: Boolean,
        idstub: String
    },
    data() {
        return {
            resultsopen: false,
            resultlist: [],
            completeloading: false,
            selected: false,
            currentitem: 0
        }
    },
    computed: {
        isloading() {
            return this.loading || this.completeloading
        },
        inputvalue: {
            get() {
                return this.value
            },
            set(value) {
                this.$emit('input', value)
            }
        }
    },
    mounted() {
        // check if completemethod actually returns a promise
        if (!(typeof this.completemethod() === 'object' && typeof this.completemethod().then === 'function')) {
            throw TypeError('Property "completemethod" must return a promise!')
        }

        if (this.value) {
            // if a value is already set, we need to correct the editing state of the autocomplete
            this.selected = true
        }
    },
    methods: {
        nextItem (event) {
            if (!this.resultsopen) {
                return
            }
            
            if (event.keyCode == 38 && this.currentitem > 0) {
                this.currentitem--
            } else if (event.keyCode == 40 && this.currentitem < this.resultlist.length -1) {
                this.currentitem++
            }
        },
        manualEntrySelect() {
            this.handleSelection(this.resultlist[this.currentitem])
        },
        clearSelection() {
            this.selected = false
            this.inputvalue = ''
            this.currentitem = 0
            this.openResults()
            this.$emit('select', null)
        },
        handleSelection(event) {
            this.selected = true
            this.inputvalue = event.displayname
            this.closeResults()
            this.$emit('select', event)
        },
        triggerSearch: _.debounce(function() {
            this.completeloading = true

            this.currentitem = 0

            this.completemethod(this.inputvalue)
            .then(result => {
                if (this.isAcceptableType(result)) {
                    this.resultlist = result
                } else {
                    throw new TypeError('Result was not a list with objects containing at least a "displayid" and a "displayname" property!')
                }
                this.completeloading = false
            })
            .catch(err => console.log(err))
        }, 750),
        isAcceptableType(result) {
            if (!Array.isArray(result)) {
                // if it's not an array we don't want it
                return false
            } else {
                // if the elements in the array do not have a 'displayid' nor 'displayname' property
                // we dont want the result either
                let isacceptable = true
                result.forEach(entry => {
                    if (!('displayid' in entry) || !('displayname' in entry)) {
                        isacceptable = false
                    }
                })
                return isacceptable
            }
        },
        closeResults() {
            this.resultsopen = false
        },
        openResults() {
            if (this.resultlist && this.resultlist.length > 0) {
                this.resultsopen = true
            }
        }
    },
    watch: {
        resultlist: function(value) {
            this.resultsopen = value.length > 0
        },
        inputvalue: function() {
            this.resultsopen = false
        }
    },
    components: {
        TextInput
    }
}
</script>

<style scoped>
.auto-complete-wrapper {
    position: relative;
}

.auto-complete-wrapper > .result-list {
    position: absolute;
    z-index: 10;
    background: #fff;
    top: 30px;
    box-shadow: 0px 2px 5px rgb(100 100 150 / 30%);
    width: fit-content;
    min-width: 100%;
    text-align: left;
    padding: 10px;
    box-sizing: border-box;
    list-style-type: none;
    border-radius: var(--input-border-radius);
    max-height: 300px;
    overflow-y: auto;
}

.auto-complete-wrapper > .reset {
    position: absolute;
    top: 22.5px;
    right: 10px;
    fill: #666;
}

.auto-complete-wrapper > .reset:hover {
    cursor: pointer;
}

.auto-complete-wrapper >>> input {
    padding-right: 25px;
}

.auto-complete-wrapper > .reset + div >>> input {
    margin-right: 30px;
}

.result-list > .result-entry {
    text-decoration: none;
    border-radius: var(--input-border-radius);
    padding: 5px;
}

.result-list > .result-entry.active,
.result-list > .result-entry:hover {
    background: rgba(0, 0, 0, 0.2);
    cursor: pointer;
}
</style>